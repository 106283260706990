/*global enquire google wp_vars locations*/
$(function(){


	// ==================== SLICK ==================== //

	if ($('.slider__slides').length) {
		$('.slider__slides').slick({
			// appendArrows: $('.slider__nav'),
			autoplay: true,
			autoplaySpeed: 5000,
			prevArrow: '<a href="#" class="slick-prev">Previous</a>',
			nextArrow: '<a href="#" class="slick-next">Next</a>',
			dots: true
		});
	}

	if ($('.slider__neighbourhood-slides').length) {
		var neighbourhood_slides = $('.slider__neighbourhood-slides');
		var slide_count = $('.slide-count');

		neighbourhood_slides.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			slide_count.text(i + '/' + slick.slideCount);
		});

		neighbourhood_slides.slick({
			autoplay: true,
			autoplaySpeed: 5000,
			prevArrow: '<a href="#" class="slick-prev">Previous</a>',
			nextArrow: '<a href="#" class="slick-next">Next</a>',
		});
	}

	if ($('.slider__property-slides').length) {
		var property_slides = $('.slider__property-slides');
		var slide_count = $('.slide-count');

		property_slides.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0-based)
			var i = (currentSlide ? currentSlide : 0) + 1;

			slide_count.text(i + '/' + slick.slideCount);
		});

		property_slides.slick({
			prevArrow: '<a href="#" class="slick-prev">Previous</a>',
			nextArrow: '<a href="#" class="slick-next">Next</a>',
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true
		});

		if ($('.slider__property-slides .slide').length === 1) {
			$('.slide').css('transform', 'translateX(-50%)');
		}
	}

	// ==================== ENQUIRE ==================== //

	enquire.register('screen and (min-width:768px)', {
		match : function() {
			$('#header-content').removeAttr('style');
			$('#toggle-nav').removeClass('nav-open');
		}
	});

	// ==================== NAV ==================== //

	$('#toggle-nav').click(function() {
		if ($('#header-content').is(':visible')) {
			$('#header-content').slideUp();
			$(this).removeClass('nav-open');
		} else {
			$('#header-content').slideDown();
			$(this).addClass('nav-open');
		}
	});

	// ==================== TEAM ==================== //

	if ($('.list--team-members').length) {
		var allPanels = $('.list--team-members .team-member__content').hide();
		var firstPanel = $('.list--team-members .team-member').first();

		firstPanel.children('.team-member__content').show();
		firstPanel.addClass('accordion-open');

		$('.list--team-members .team-member .accordion-button').click(function() {
			var $this = $(this).parents('.team-member');
			var $target = $this.children('.team-member__content');

			if (!$this.hasClass('accordion-open')) {
				$('.list--team-members .team-member').removeClass('accordion-open');
     				allPanels.slideUp();
				$this.addClass('accordion-open');
				$target.slideDown();
			}
			return false;
		});
	}

	// ==================== PROPERTIES ==================== //


	if ($('select').length) {
		$('.select--properties').selectric({
			// inheritOriginalWidth: true,
			maxHeight: 240,
			arrowButtonMarkup: '<b class="selectric-button">&#x25be;</b>'
		});

		$('#sort_date').change(function() {
			$('#sort_price option').removeAttr();
			$('#sort_price option').first().attr('selected', true);
			$('.selectric-select--sort-price .label').text('Sort by Price');
		});

		$('#sort_price').change(function() {
			$('#sort_date option').removeAttr();
			$('#sort_date option').first().attr('selected', true);
			$('.selectric-select--sort-date .label').text('Sort by Date');
		});

	}

	// ==================== SINGLE PROPERTY ==================== //

	$('#request_showing').click(function(e) {
		e.preventDefault();
		$(this).hide(0, function() {
			$('#ninja_forms_form_8_cont').slideDown();
		});
	});

	// ==================== GOOGLE MAP STYLES ==================== //

	var map_style = {
		// Paper from snazzy maps (customized)
		neighbourhood: [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#0066ff"},{"saturation":74},{"lightness":100}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"off"},{"weight":0.6},{"saturation":-85},{"lightness":61}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":"0"},{"gamma":"0.45"}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#5f94ff"},{"lightness":26},{"gamma":5.86}]}],
		// Paper from snazzy maps (customized) with all road labels visible
		property: [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#0066ff"},{"saturation":74},{"lightness":100}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"off"},{"weight":0.6},{"saturation":-85},{"lightness":61}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":"0"},{"gamma":"0.45"}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#5f94ff"},{"lightness":26},{"gamma":5.86}]}],
		// Ultra Light with Labels from snazzy maps
		contact: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
	};

	// ==================== SINGLE NEIGHBOURHOOD ==================== //

	if ($('#photo-credits-button').length) {
		$('#photo-credits-button').click(function(e) {
			e.preventDefault();
			$('#photo-credits').slideToggle();
		});
	}

	if ($('#neighbourhood-stats-source-button').length) {
		$('#neighbourhood-stats-source-button').click(function(e) {
			e.preventDefault();
			$('#neighbourhood-stats-source').slideToggle();
		});
	}

	// ==================== NEIGHBOURHOOD MAP ==================== //

	if ($('#neighbourhood-map').length) {
		var neighbourhood_coords = coords.geometry.coordinates[0];
		var infowindow;
		var map;
		var markers_array = [];

		initMap('neighbourhood-map', map_style.neighbourhood);

		$('#neighbourhood-map_marker-type').selectric({
			// inheritOriginalWidth: true,
			maxHeight: 240,
			arrowButtonMarkup: '<b class="selectric-button">&#x25be;</b>'
		});
	}

	// ==================== CONTACT MAP ==================== //

	if ($('#contact-map').length) {
		var infowindow;
		var map;
		var markers_array = [];

		initMap('contact-map', map_style.contact);
	}

	// ==================== SINGLE PROPERTY MAP ==================== //

	if ($('#property-map').length) {
		var infowindow;
		var map;
		var markers_array = [];

		initMap('property-map', map_style.property);
	}

	// ==================== GOOGLE MAPS FUNCTIONS ==================== //

	function initMap(el_id, style) {
		var mapCanvas = document.getElementById(el_id, style);
		var mapOptions = {
			// zoom: 14,
			// center: {lat: 43.6501423, lng: -79.3978898},
			zoomControl: true,
			mapTypeControl: false,
			streetViewControl: false,
			scrollwheel: false,
			styles: style
		};

		if (el_id === 'property-map') {
			var property_latlng = new google.maps.LatLng(property_coords.lat, property_coords.lng);
			mapOptions.center = property_latlng;

			enquire
				.register('screen and (min-width: 0)', function() {
					mapOptions.zoom = 15;
				})
				.register('screen and (min-width: 769px)', function() {
					mapOptions.zoom = 16;
				})
				.register('screen and (min-width: 960px)', function() {
					mapOptions.zoom = 17;
				});
		}

		map = new google.maps.Map(mapCanvas, mapOptions);
		infowindow = new google.maps.InfoWindow();

		if (el_id === 'property-map') {
			var marker = new google.maps.Marker({
				icon: wp_vars.theme_url + '/dist/img/map-marker.svg',
				position: property_latlng
			});

			marker.setMap(map);

			$(window).resize(function() {
				map.setCenter(property_latlng);
			});

			$(window).on('orientationchange', function() {
				map.setCenter(property_latlng);
			});
		}

		if (el_id === 'neighbourhood-map') {
			set_poly(map, el_id);
		} else if (el_id === 'contact-map') {
			setMarkers(null, null, map, el_id);
		}
	}

	// Only for single neighbourhood map
	function set_poly(map, el_id) {
		var poly = new google.maps.Polygon({
			paths: neighbourhood_coords,
			strokeColor: '#ad845e',
			strokeOpacity: 1,
			strokeWeight: 5,
			fillColor: '#ad845e',
			fillOpacity: 0
		});

		poly.setMap(map);

		setBounds(poly, map, el_id);
	}

	// Only for single neighbourhood map
	function setBounds(poly, map, el_id) {
		var latlngbounds = new google.maps.LatLngBounds();

		$.each(neighbourhood_coords, function(attraction, latlng) {
			var poi_latlng = new google.maps.LatLng(latlng.lat, latlng.lng);

			latlngbounds.extend(poi_latlng);
		});

		// Center map and adjust Zoom based on the position of all markers.
		map.setCenter(latlngbounds.getCenter());
		map.fitBounds(latlngbounds);

		$(window).resize(function() {
			map.setCenter(latlngbounds.getCenter());
			map.fitBounds(latlngbounds);
		});

		$(window).on('orientationchange', function() {
			map.setCenter(latlngbounds.getCenter());
			map.fitBounds(latlngbounds);
		});

		setMarkers(latlngbounds, poly, map, el_id);
	}

	function setMarkers(latlngbounds, poly, map, el_id) {
		if (el_id === 'neighbourhood-map') {
			// Specify location, radius and place types for your Places API search.
			var request = {
				bounds: latlngbounds,
			};

			$('#neighbourhood-map_marker-type').change(function() {
				marker_type = places[$(this).val()];
				request.types = [marker_type];

				if (markers_array.length !== 0) {
					for ( i = 0; i < markers_array.length; i++) {
						markers_array[i].setMap(null);
					}
				}

				markerService(poly, map, request, el_id);
			});
		} else if (el_id === 'contact-map') {
			for (var i = 0; i < locations.length; i++) {
				var office = locations[i];

				if (office.lat && office.lng) {
					createMarker(locations[i], el_id);
				}
			}
		}
	}

	// Only for single neighbourhood map
	function markerService(poly, map, request, el_id) {
		// Create the PlaceService and send the request.
		// Handle the callback with an anonymous function.
		var service = new google.maps.places.PlacesService(map);
		service.nearbySearch(request, function(results, status) {
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				for (var i = 0; i < results.length; i++) {
					var place = results[i];
					var place_location = place.geometry.location;

					if (google.maps.geometry.poly.containsLocation(place_location, poly)) {
						createMarker(results[i], el_id);
					}
				}
			}
		});
	}

	function createMarker(place, el_id) {
		if (el_id === 'contact-map') {
			var placeLoc = {
				lat: Number(place.lat),
				lng: Number(place.lng)
			};
		} else {
			var placeLoc = place.geometry.location;
		}

		var marker = new google.maps.Marker({
			icon: wp_vars.theme_url + '/dist/img/map-marker.svg',
			map: map,
			position: placeLoc
		});

		markers_array.push(marker);

		if (el_id === 'contact-map') {
			autoCenter();

			$(window).resize(function() {
				autoCenter();
			});

			$(window).on('orientationchange', function() {
				autoCenter();
			});
		} else {
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.setContent(place.name);
				infowindow.open(map, this);
			});
		}
	}

	// Only for contact map
	function autoCenter() {
		var bounds = new google.maps.LatLngBounds();

		$.each(markers_array, function (index, marker) {
			bounds.extend(marker.position);
		});

		map.fitBounds(bounds);

		google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
			this.setZoom(this.getZoom() - 0.5);
		});
	}

	// ==================== BLOG CATEGORIES ==================== //
	if ($('#blog_categories').length) {
		$('#blog_categories').selectric({
			maxHeight: 240,
			arrowButtonMarkup: '<b class="selectric-button">&#x25be;</b>'
		});
	}

	// ==================== BLOG COMMENTS ==================== //

	// Fake focus on textarea
	$('#comment').focus(function() {
		$(this).parent('.wrapper--textarea-comment').addClass('is-focused');
	});

	$('#comment').blur(function() {
		$(this).parent('.wrapper--textarea-comment').removeClass('is-focused');
	});
});
